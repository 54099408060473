import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col} from "reactstrap";

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
  state = {
    links: [
    ],
  };
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              {this.state.links.map((fLink, key) => (
                <Col key={key} lg={4} className="mt-4">
                  <h4>{fLink.title}</h4>
                  <div className="text-muted mt-4">
                    <ul className="list-unstyled footer-list">
                      {fLink.child.map((fLinkChild, key) => (
                        <li key={key}>
                          <Link to={fLinkChild.link}>{fLinkChild.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}

            </Row>
          </Container>
        </footer>
        {/* Render footer links */}
        <FooterLinks />

      </React.Fragment>
    );
  }
}

export default Footer;
