import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import MissionBox from "./mission-box";

class Mission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services1: [
        {
          icon: "pe-7s-tools",
          title: "Radical.Studio",
          status: "beta released - v0.1.0",
          desc:
            "Build and maintain the architecture models on your local machine or integrate Radical.Hub for the out-of-the-box collaboration capabilities",
          enabled: true,
        },
        {
          icon: "pe-7s-share",
          title: "Radical.Hub",
          status: "design phase",
          desc:
          "Create data- and collaboration-oriented ecosystem with well-defined API for third-party integrations",
          enabled: false,
        },
        {
          icon: "pe-7s-speaker",
          title: "Radical.Wiki",
          status: "ideation phase",
          desc:
            "Share always up-to-date architecture content in wiki-like form",
          enabled: false,
        },
      ],
      services2: [
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className={"section " + this.props.sectionClass} id="mission">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Mission"
              desc= <span>Our mission is to deliver a <b>unified ecosystem</b> to support IT Architecture management during the entire life cycle. <br/>
              Our modern and easy to use solution addresses three pillars: <b>Modelling, Collaboration and Presentation</b>.
              <br/><br/>We believe in the <b>open source</b> development model. Code should be open, accessible, and shared with the community. By being freely available, it enables and encourages collaboration and the development of the solution.
            </span>
            />

            <Row className="mt-4">
              {/* services box */}
              <MissionBox services={this.state.services1} />
            </Row>

            <Row className="mt-4">
              {/* service box */}
              <MissionBox services={this.state.services2} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Mission;
