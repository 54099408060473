import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Importing Modal
import ModalSection from "../../components/common/ModalSection";

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.callModal.bind(this);
  }

  callModal = () => {
    this.refs.child.openModal();
  };

  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-home home-half"
          data-image-src="images/bg-home.jpg"
          id="home"
        >
          <div className="bg-overlay" />
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={8} className="text-white text-center">
                    <h1 className="home-title">
                      Open Source IT Architecture Management Ecosystem
                    </h1>
                    <p >
                      <img src="assets/images/radical-tools.jpg" className="img-fluid center-block mt-4"alt="pattern" />
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          {/* Render ModalSection Component for Modal */}
          <ModalSection ref="child" channel="vimeo" videoId="638031454" />
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
