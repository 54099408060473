import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import DocBox from "./DocBox";
import ModalSection from "../common/ModalSection";

class Docs extends Component {

  callModal = (index) => {
    this.refs['tutorialPlayer'].openModal();
    this.setState({ videoId: this.state.docs[index].videoId })
  };

  constructor(props) {
    super(props);
    this.state = {
      videoId: 0,
      docs: [
        {
          image: "assets/images/docs/basic-concepts.gif",
          category: "Radical.Studio - Foundations",
          title: "basic concepts",
          desc:
              "learn about Metamodel, Model and View",
          videoId: 638031454,
        },
        {
          image: "assets/images/docs/hello-world.jpg",
          category: "Radical.Studio - General",
          title: "hello world",
          desc:
            "learn how to create the project and build your first C4 model",
          videoId: 638208162,
        },
        {
          image: "assets/images/docs/changes-tracking.jpg",
          category: "Radical.Studio - General",
          title: "model changes",
          desc:
              "learn how to track the model changes",
          videoId: 638395375,
        },
      ],
    };
  }

  render() {
    return (
      <section className="section bg-light pt-5" id="resources">
        <Container>
          {/* section title */}
          <SectionTitle
            title="Resources"
            desc="All you need to learn more about Radical.Tools (i.e. video presentations, tutorials, manuals, whitepapers). This section is constantly updated and expanded."
          />

          <Row className="mt-4">
            {/* docs box */}
            <DocBox blogs={this.state.docs} onPlayClicked={this.callModal} />
          </Row>
        </Container>
        <ModalSection ref='tutorialPlayer' channel="vimeo" videoId={this.state.videoId} />
      </section>
    );
  }
}

export default Docs;
