import React, { Component } from "react";
import { Col } from "reactstrap";

class MissionBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.services.map((service, key) => (
          <Col lg={4} key={key} className="mt-3">
            <div className="services-box text-center hover-effect ">
              <i className={service.icon + " text-primary"}></i>
              <h3 className="pt-3" >{service.title}</h3>
              <h5 className="pt-3">{service.status}</h5>
              <p className="pt-3 text-muted ">{service.desc}</p>
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default MissionBox;
