import React, { Component } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Service from "../../components/Mission/mission"
import Footer from "../../components/Footer/footer";
import Docs from "../../components/Docs/Docs";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        { id: 2, idnm: "mission", navheading: "Mission" },
        { id: 3, idnm: "resources", navheading: "Resources" },

      ],
      navClass: "",
    };
  }

  render() {
    return (
      <React.Fragment>
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
        />

        <Section />
        <Service />
        <Docs />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index;
